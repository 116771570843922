import Vue from 'vue';
// initial state
const state = {
	user: {},
	userID: '',
	token: '',
	isProfileOpen: false,
};
const initial = JSON.parse(JSON.stringify(state));
// getters
const getters = {
	getUserID(state) {
		return state.userID;
	},
	getUserInfo(state) {
		return state.user;
	},
	getUserToken(state) {
		return state.token;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	addUser(state, user) {
		Vue.set(state, 'user', user);
	},
	addUserID(state, user) {
		state.userID = user;
	},
	addUserToken(state, token) {
		state.token = token;
	},
	resetUser(state) {
		state.user = {};
		state.userID = '';
		state.token = '';
		state.isProfileOpen = false;
	},
	addUserInstitution(state, institution_id) {
		state.user.institution = {};
		state.user.institution.id = institution_id;
	},
	setUserPoints(state, points) {
		state.user.points = points;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
