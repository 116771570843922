<template>
	<footer class="footer" :class="isSidebarOpen && canSidebarBeOpen ? 'sidebar-open' : ''">
		<div class="columns is-mobile is-multiline is-centered">
			<div class="column is-half-touch is-half-desktop is-4-widescreen is-2-fullhd">
				<img class src="./../../assets/logo/logo.svg" />
			</div>
			<div class="column is-half-touch is-half-desktop is-4-widescreen is-2-fullhd">
				<a @click.prevent="scrollTo('ComercialPage', 'about')" class="is-secondary-dark">{{ $t('components.Parts.Footer.about') }}</a>
				<br />
				<a class="is-primary">{{ $t('components.Parts.Footer.services') }}</a>
				<br />
				<a @click.prevent="scrollTo('ComercialPage', 'partners')" class="is-primary">{{ $t('components.Parts.Footer.partners') }}</a>
				<br />
				<a class="is-primary" @click.prevent="openSizes()">{{ $t('components.Parts.Footer.size_guide') }}</a>
				<br />
				<a class="is-primary" @click.prevent="setFAQ(true)">{{ $t('components.Parts.Footer.faq') }}</a>
			</div>
			<div class="column is-half-touch is-half-desktop is-4-widescreen is-2-fullhd">
				<h5>{{ $t('components.Parts.Footer.contacts') }}</h5>
				<a :href="`tel:${$t('contacts.tel')}`">{{ $t('contacts.tel_spaces') }}</a>
				<br />
				<a :href="`mailto:${$t('contacts.email')}`">{{ $t('shared.email') }}: {{ $t('contacts.email') }}</a>
				<br />
				<a @click.prevent="$router.push({ name: 'Contact' })">{{ $t('components.Parts.Footer.contact_form') }}</a>
				<br />
				<a @click.prevent="scrollTo('ComercialPage', 'where')">{{ $t('components.Parts.Footer.contacts_stores') }}</a>
			</div>
			<div class="column is-half-touch is-half-desktop is-4-widescreen is-2-fullhd">
				<a @click.prevent="openPolicy('TermsConditions')">{{ $t('components.Parts.Footer.terms_conditions') }}</a>
				<br />
				<a @click.prevent="openPolicy('PrivacyPolicy')">Política de Privacidade</a>
				<br />
				<a @click.prevent="openPolicy('CookiesPolicy')">Política de Cookies</a>
				<br />
				<a @click.prevent="openPolicy('MyRights')">Os meus direitos</a>
			</div>
			<div class="column is-12-mobile is-6-tablet has-text-right is-half-desktop is-4-widescreen is-2-fullhd">
				<h5 class="has-text-left">
					{{ $t('components.Parts.Footer.subscribe') }}
				</h5>
				<form :action="mailchimp" method="post" target="_blank" novalidate>
					<b-input
						name="email"
						id="mce-EMAIL"
						type="email"
						style="margin-top: 10px; margin-bottom: 5px"
						rounded
						class="required email"
						:placeholder="$t('components.Parts.Footer.write_email')"
					></b-input>
					<b-button native-type="submit" type="is-primary">{{ $t('shared.send') }}</b-button>
				</form>
			</div>
			<div class="column is-full-touch is-half-desktop is-4-widescreen is-2-fullhd">
				<div class="columns is-mobile is-centered is-multiline">
					<div class="column has-text-centered is-3-mobile is-1-tablet is-3-desktop" v-for="(net, i) in networks" :key="i">
						<a target="__blank" :href="net.link">
							<div
								class="icon-net"
								:style="{
									'mask-image': `url(${net.img})`,
									'-webkit-mask-image': `url(${net.img})`,
								}"
							></div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="columns is-centered is-multiline">
			<div class="column is-3 has-text-centered">
				<span> © {{ new Date().getFullYear() }} ToBeGreen. All rights reserved. </span>
			</div>
			<div class="column is-12 has-text-centered">
				<span>v{{ version }}</span>
			</div>
		</div>
		<FAQ v-if="isFAQOpen" @close="setFAQ(false)" :openIndex="FAQindex" />
	</footer>
</template>

<script>
	import NETWORKS from '@/config/Networks';
	import FAQ from '@/components/Other/FAQ';
	import CONFIG from '@/config/config';

	export default {
		name: 'Footer',
		components: { FAQ },
		data() {
			return {
				networks: NETWORKS,
				isFAQOpen: false,
				mailchimp: CONFIG.MAILCHIMP_URL,
				FAQindex: null,
				version: process.env.VUE_APP_VERSION,
			};
		},
		methods: {
			setFAQ(bol) {
				this.isFAQOpen = bol;
				this.FAQindex = null;
			},
			openSizes() {
				this.isFAQOpen = true;
				this.FAQindex = 1;
			},
			openPolicy(name) {
				let routeData = this.$router.resolve({
					name,
				});
				window.open(routeData.href, '_blank');
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '~bulma/sass/utilities/mixins';
	@import '@/assets/scss/variables/all';
	footer > .columns:nth-of-type(1) > .column {
		padding-bottom: 30px;
	}

	footer > .columns:nth-of-type(1) > .column:nth-of-type(1) {
		order: 1;
	}
	footer > .columns:nth-of-type(1) > .column:nth-of-type(2) {
		order: 2;
		@include until($desktop) {
			order: 3;
		}
	}
	footer > .columns:nth-of-type(1) > .column:nth-of-type(3) {
		order: 3;
		@include until($desktop) {
			order: 2;
		}
	}
	footer > .columns:nth-of-type(1) > .column:nth-of-type(4) {
		order: 4;
	}
	footer > .columns:nth-of-type(1) > .column:nth-of-type(5) {
		order: 5;
	}
	footer > .columns:nth-of-type(1) > .column:nth-of-type(6) {
		order: 6;
	}

	// * {
	// 	color: $secondary-dark;

	// }

	a,
	h5 {
		color: $secondary-dark;
		font-size: 16px;
		font-weight: bold;
	}

	h5 {
		text-decoration: underline;
	}

	.footer {
		// width: 100%;
		padding-bottom: 25px;
	}

	img {
		width: 100%;
		max-height: 123px;
	}

	@media only screen and (min-width: 769px) {
		.margin-left {
			margin-left: 260px;
		}
	}

	.icon-net {
		height: 35px;
		width: 35px;
		background-color: $primary;
		-webkit-mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-position: center;
		margin: auto;
	}
</style>
