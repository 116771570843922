import store from '@/store/index';
export const notifications = {
	computed: {
		getNotifications() {
			return store.getters['notifications/getNotifications'];
		},
		// isNotificationsOpen() {
		//     return store.getters['notifications/getisNotificationsOpen']
		// },
		unreadNotificationsNumber() {
			let notifications = store.getters['notifications/getNotifications'];

			return notifications.filter((n) => n.read == false).length;
		},
	},
	methods: {
		saveNotifications(notifications) {
			store.commit('notifications/saveNotifications', notifications);
		},
		pushNotification(notification) {
			let notifications = store.getters['notifications/getNotifications'];

			notifications.unshift(notification);
			store.commit('notifications/saveNotifications', notifications);
		},
		readNotification(_id) {
			let notifications = store.getters['notifications/getNotifications'];

			for (let notification of notifications) {
				if (notification._id == _id) {
					notification.read = true;
				}
			}
			store.commit('notifications/saveNotifications', notifications);
		},
	},
};
