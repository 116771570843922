<template>
	<div class="navbar-modal has-navbar-fixed-top">
		<div v-if="cart.products.length <= 0">
			<ul class="navbar-modal-items">
				<h1 class="subtitle is-6">Ainda não adicionou nenhum artigo</h1>
			</ul>
		</div>
		<div v-else>
			<ul v-for="(product, i) in cart.products" v-bind:key="i" class="navbar-modal-items">
				<li class="clearfix">
					<img class="product img" :src="checkImg(product.product.imgs[0])" @error="getDefaultImg" />
					<p class="item-name">
						{{ product.product.description }}
						{{ product.product.color }}
					</p>
					<p class="item-name">{{ product.product.genre }} - {{ product.product.size }}</p>
					<p class="item-quantity">1x Artigo</p>
					<!-- v-if="getAppSelected._id != 'app'" -->
					<p class="item-price">{{ product.points }} Pontos</p>
					<!-- <p v-else class="item-price">
                        <span v-if="getUserInfo.shareholder">{{ product.price.regularized }} €</span>
                        <span v-else>{{ product.price.not_regularized }} €</span>
					</p>-->
				</li>
			</ul>
			<br />
			<div class="has-text-centered">
				<b-button @click="goToCart" class="is-secondary is-outlined">Ver carrinho</b-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartModal',
		props: {
			cart: {
				app_id: {
					type: String,
					default: 'app',
				},
				products: {
					type: Array,
					default: [],
				},
			},
		},
		data() {
			return {};
		},
		methods: {
			goToCart() {
				this.$emit('gotocart');
			},
		},
	};
</script>

<style lang="scss" scoped>
	/* @import "../../assets/scss/variables/colors"; */
</style>
