import store from '@/store/index';
export const order = {
	computed: {
		getSelectedPlace() {
			return store.getters['order/getSelectedPlace'];
		},
		getSelectedPlaceID() {
			return store.getters['order/getSelectedPlaceID'];
		},
	},
	methods: {
		setSelectedPlace: function (place) {
			store.commit('order/setSelectedPlace', place);
		},
		resetProcess: function () {
			store.commit('order/resetProcess');
		},
	},
};
