import store from '@/store/index';
export const cart = {
	computed: {
		getPaymentType() {
			return store.getters['cart/getPaymentType'];
		},
		getAllCarts() {
			return store.getters['cart/getItemsCart'];
		},
	},
	methods: {
		changePaymentType: function (type) {
			store.commit('cart/changePaymentType', type);
		},
		getCart(app_id) {
			let cart = store.getters['cart/getItemsCart'].find((cart) => cart.app_id == app_id);
			if (cart) return cart;

			return {
				app_id,
				products: [],
			};
		},
		isInCart(app_id, product_id) {
			let cart = store.getters['cart/getItemsCart'].find((cart) => cart.app_id == app_id);
			if (!cart) return false;

			let found = cart.products.filter((e) => e._id == product_id)[0];

			if (found) return true;
			return false;
		},
		addToCart: function (app_id, product) {
			store.commit('cart/addToCart', {
				app_id,
				product,
			});
		},
		removeFromCart: function (app_id, product) {
			store.commit('cart/removeFromCart', {
				app_id,
				product,
			});
		},
		resetCart: function (app_id) {
			store.commit('cart/resetCart', app_id);
		},
		resetAllCarts: function () {
			store.commit('cart/resetAllCarts');
		},
	},
};
