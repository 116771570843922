import { user } from '@/mixins/modules/user';

export default function (to, from, next) {
	if (to.name == from.name) return next();
	let isAllowed = !user.computed.fromSpecialPlace();
	if (isAllowed) {
		next();
	} else {
		next({
			name: 'MyProducts',
		});
	}
}
