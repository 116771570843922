<template>
	<div class="app" v-if="toggleStart">
		<component :is="$route.meta.navbarType" />
		<div
			class="content-wrap"
			:class="{
				'sidebar-open': isSidebarOpen && canSidebarBeOpen,
				'has-navbar-fixed-top': $route.meta.navbarType == 'AppNavBar',
				'cm-margin-top': $route.meta.navbarType == 'NavBar',
			}"
		>
			<FormCompleteRegister v-if="getUserInfo.isRegistered == false && $store.getters['user/getUserToken']" :modalOpen="true" />
			<router-view v-else />
		</div>

		<Footer v-if="$route.meta.showFooter !== false" />
	</div>
</template>

<script>
	import { tokenAuth } from './api/auth/login';
	import NavBar from './components/Parts/NavBar';
	import AppNavBar from './components/Parts/AppNavBar';
	import FormCompleteRegister from './components/Forms/FormCompleteRegister';
	import Footer from './components/Parts/Footer';

	import { registerInstall } from './api/info/info.js';

	import { notifications } from './mixins/modules/notifications';

	import { SplashScreen } from '@capacitor/splash-screen';

	export default {
		mixins: [notifications],
		components: {
			NavBar,
			AppNavBar,
			FormCompleteRegister,
			Footer,
		},
		mounted() {
			SplashScreen.hide();
		},
		created() {
			addEventListener('appinstalled', () => {
				registerInstall();
			});

			this.$store.commit('navbar/resetStore');

			if (process.env.NODE_ENV === 'production') {
				this.$ga.enable();
			}

			addEventListener('resize', () => {
				this.updateWidth(innerWidth);
			});

			if (process.env.VUE_APP_CAPACITOR_PLATFORM && process.env.VUE_APP_CAPACITOR_PLATFORM != 'browser') {
				this.$router.push({ name: 'Login' });
			}

			tokenAuth()
				.then((response) => {
					this.addUser(response.data.body);
					this.addUserID(response.data.body._id);
					this.addUserToken(response.headers.authorization);
				})
				.catch((error) => {
					throw error;
				});
		},
		data() {
			return {
				toggleStart: true,
			};
		},
		watch: {
			getRefreshStatus() {
				this.toggleStart = false;
				this.$store.commit('navbar/resetStore');
				this.$nextTick(() => {
					this.toggleStart = true;
				});
			},
		},
		sockets: {
			connect() {
				if (this.$store.getters['user/getUserToken']) {
					this.$socket.emit('get_notifications', this.getUserID);
				}
			},
			get_notifications(notifications) {
				this.saveNotifications(notifications);
			},
			new_notification(notification) {
				this.pushNotification(notification);
				this.setNotificationsOpen(true);
				if (notification.ntype == 'order_product_unavailable') {
					let currentPoints = parseInt(this.getUserInfo.points) + parseInt(notification.payload.points);
					this.setUserPoints(currentPoints);
				}
			},
		},
	};
</script>

<style lang="scss">
	.app {
		font-family: 'Nunito Sans', sans-serif;
	}
	.content-wrap {
		min-height: 100vh;
	}

	.cm-margin-top {
		margin-top: 62.38px;
	}
</style>
