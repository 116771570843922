import Vue from 'vue';

import { app } from './modules/app';
// import {
//     cart
// } from './modules/cart'
import { device } from './modules/device';
// import {
//     filters
// } from './modules/filters'
// import {
//     lastLocation
// } from './modules/lastLocation'
// import {
//     locations
// } from './modules/locations'
// import {
//     map
// } from './modules/map'
import { navbar } from './modules/navbar';
// import {
//     notifications
// } from './modules/notifications'
// import {
//     product
// } from './modules/product'
// import {
//     order
// } from './modules/order'
import update from './modules/update';
import { user } from './modules/user';

let mixins = [
	app,
	// cart,
	device,
	// filters,
	// locations,
	// lastLocation,
	// map,
	navbar,
	// notifications,
	// order,
	// product,
	user,
];

for (let mixin of mixins) Vue.mixin(mixin);
