<template>
	<div>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">{{ t.title }}</p>
					<button class="delete" aria-label="close" @click="closeThis"></button>
				</header>
				<section class="modal-card-body">
					<b-collapse :open="indexOpen == 0" @open="setIndexOpen(0)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['0'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['0'].texts" :key="'a' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 1" @open="setIndexOpen(1)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['1'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['1'].texts" :key="'b' + i">
									{{ text }}
								</p>

								<b-collapse :open="sizeIndexOpen == 0" @open="setSizeIndexOpen(0)" class="card child-collapse" animation="slide">
									<template #trigger="props">
										<div class="card-header" role="button">
											<p class="card-header-title">
												{{ t['1'].child['0'].title }}
											</p>
											<a class="card-header-icon">
												<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
											</a>
										</div>
									</template>

									<div class="card-content">
										<div class="content">
											<div class="has-text-centered">
												<img src="../../assets/img/faq/sizes/baby.svg" alt />
											</div>
											<div class="has-text-centered">
												<ImgZoom :imgsrc="require('@/assets/img/faq/sizes/baby-sizes.svg')" :zoom="5" bc="white" />
												<!-- <img src="../../assets/img/faq/sizes/baby-sizes.svg" alt /> -->
											</div>
										</div>
									</div>
								</b-collapse>
								<b-collapse :open="sizeIndexOpen == 1" @open="setSizeIndexOpen(1)" class="card" animation="slide">
									<template #trigger="props">
										<div class="card-header" role="button">
											<p class="card-header-title">
												{{ t['1'].child['1'].title }}
											</p>
											<a class="card-header-icon">
												<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
											</a>
										</div>
									</template>

									<div class="card-content">
										<div class="content">
											<div class="has-text-centered">
												<img src="../../assets/img/faq/sizes/child.svg" alt />
											</div>
											<div class="has-text-centered">
												<ImgZoom :imgsrc="require('@/assets/img/faq/sizes/child-sizes.svg')" :zoom="5" bc="white" />
											</div>
										</div>
									</div>
								</b-collapse>
								<b-collapse :open="sizeIndexOpen == 2" @open="setSizeIndexOpen(2)" class="card" animation="slide">
									<template #trigger="props">
										<div class="card-header" role="button">
											<p class="card-header-title">
												{{ t['1'].child['2'].title }}
											</p>
											<a class="card-header-icon">
												<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
											</a>
										</div>
									</template>

									<div class="card-content">
										<div class="content">
											<div class="has-text-centered">
												<img src="../../assets/img/faq/sizes/men-body.svg" alt />
												<img src="../../assets/img/faq/sizes/men-legs.svg" alt />
											</div>
											<div class="has-text-centered">
												<ImgZoom :imgsrc="require('@/assets/img/faq/sizes/men-body-sizes.svg')" :zoom="5" bc="white" />
											</div>
											<div class="has-text-centered">
												<ImgZoom :imgsrc="require('@/assets/img/faq/sizes/men-legs-sizes.svg')" :zoom="5" bc="white" />
											</div>
										</div>
									</div>
								</b-collapse>
								<b-collapse :open="sizeIndexOpen == 3" @open="setSizeIndexOpen(3)" class="card" animation="slide">
									<template #trigger="props">
										<div class="card-header" role="button">
											<p class="card-header-title">
												{{ t['1'].child['3'].title }}
											</p>
											<a class="card-header-icon">
												<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
											</a>
										</div>
									</template>

									<div class="card-content">
										<div class="content">
											<div class="has-text-centered">
												<img src="../../assets/img/faq/sizes/women.svg" alt />
											</div>
											<div class="has-text-centered">
												<ImgZoom :imgsrc="require('@/assets/img/faq/sizes/women-sizes.svg')" :zoom="5" bc="white" />
											</div>
										</div>
									</div>
								</b-collapse>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 2" @open="setIndexOpen(2)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['2'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['2'].texts" :key="'c' + i">
									{{ text }}
								</p>
								<div v-for="(text, i) in t['2'].child" :key="'d' + i">
									<h2>{{ text.title }}</h2>
									<div v-for="(text, k) in text.texts" :key="'e' + k">
										<span>{{ text }}</span>
									</div>
								</div>

								<p v-for="(text, i) in t['2'].texts2" :key="'f' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 3" @open="setIndexOpen(3)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['3'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['3'].texts" :key="'g' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 4" @open="setIndexOpen(4)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['4'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['4'].texts" :key="'h' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 5" @open="setIndexOpen(5)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['5'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['5'].texts" :key="'i' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 6" @open="setIndexOpen(6)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['6'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['6'].texts" :key="'j' + i">
									{{ text }}
								</p>
								<div class="has-text-centered">
									<b-button type="is-primary">{{ t['6'].button }}</b-button>
								</div>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 7" @open="setIndexOpen(7)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['7'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['7'].texts" :key="'k' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 8" @open="setIndexOpen(8)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['8'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['8'].texts" :key="'l' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 9" @open="setIndexOpen(9)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['9'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['9'].texts" :key="'m' + i">
									{{ text }}
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 10" @open="setIndexOpen(10)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['10'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p v-for="(text, i) in t['10'].texts" :key="'n' + i">
									{{ text }}
								</p>
								<div class="has-text-centered">
									<b-button type="is-primary" @click="openLocations()">{{ t['10'].button }}</b-button>
								</div>
							</div>
						</div>
					</b-collapse>

					<b-collapse :open="indexOpen == 11" @open="setIndexOpen(11)" class="card" animation="slide">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ t['11'].title }}
								</p>
								<a class="card-header-icon">
									<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
								</a>
							</div>
						</template>

						<div class="card-content">
							<div class="content">
								<p
									v-for="(text, i) in t['11'].texts.length"
									:key="'o' + i"
									v-html="$t(`components.Other.FAQ.11.texts.${parseInt(text) - 1}`, [$t('contacts.email')])"
								></p>
								<div class="has-text-centered">
									<b-button type="is-primary" @click="openContactForm()">{{ t['11'].button }}</b-button>
								</div>
							</div>
						</div>
					</b-collapse>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
	import ImgZoom from '@/components/Shared/ImgZoom';
	export default {
		name: 'FAQ',
		components: { ImgZoom },
		props: {
			openIndex: {
				type: Number,
				default: null,
			},
		},
		data() {
			return {
				indexOpen: null,
				sizeIndexOpen: null,
				t: this.$t('components.Other.FAQ'),
			};
		},
		created() {
			this.indexOpen = this.openIndex;
		},
		methods: {
			closeThis() {
				this.$emit('close');
			},
			setIndexOpen(i) {
				this.indexOpen = i;
			},
			setSizeIndexOpen(i) {
				this.sizeIndexOpen = i;
			},
			openLocations() {
				this.scrollTo('ComercialPage', 'where');
				this.closeThis();
			},
			openContactForm() {
				this.$router.push({ name: 'Contact' });
				this.closeThis();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/colors';
	a.card-header-icon {
		color: black;
	}
	.modal-card-body {
		padding-right: 0;
		padding-left: 0;

		> .card {
			padding-bottom: 20px;
		}

		.card-header-title {
			color: $primary-dark;
		}
	}
	.card-content {
		margin-right: 20px;
		margin-left: 20px;
	}

	.modal-card-body > .card:nth-of-type(2) .card-content {
		.card:nth-of-type(1) {
			img {
				max-width: 150px;
			}
		}
		.card:nth-of-type(2) {
			img {
				max-width: 300px;
			}
		}
		.card:nth-of-type(3) {
			img {
				max-width: 75px;
			}
		}
		.card:nth-of-type(4) {
			img {
				max-width: 75px;
			}
		}

		.has-text-centered:nth-of-type(2) > img,
		.has-text-centered:nth-of-type(3) > img {
			width: 100%;
			max-width: unset;
		}

		.has-text-centered {
			margin-bottom: 20px;
		}
	}
	.modal-card-body > .card:nth-of-type(3) .card-content {
		.content > div > div {
			display: grid;
			margin-left: 10%;
		}
		.content > div > div:last-of-type {
			margin-bottom: 20px;
		}

		span {
			font-weight: bold;
		}
	}
</style>
