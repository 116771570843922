import router from '@/router/index';
import { ToastProgrammatic as Toast } from 'buefy';
import store from '@/store/index';

export const user = {
	computed: {
		fromSpecialPlace() {
			let user = store.getters['user/getUserInfo'];
			if (user?.info?.location?.municipality) return user.info.location.municipality == 'Lisboa';
			else return false;
		},
		getUserID() {
			return store.getters['user/getUserID'];
		},
		getUserInfo() {
			return store.getters['user/getUserInfo'];
		},
		getUserToken() {
			return store.getters['user/getUserToken'];
		},
	},
	methods: {
		resetStores: function () {
			store.commit('user/resetStore');
			store.commit('cart/resetStore');
			store.commit('map/resetStore');
			store.commit('navbar/resetStore');
			store.commit('order/resetStore');
			store.commit('product/resetStore');
			store.commit('app/resetStore');
		},
		addUser: function (user) {
			store.commit('user/addUser', user);
		},
		addUserID: function (user) {
			store.commit('user/addUserID', user);
		},
		addUserToken: function (token) {
			store.commit('user/addUserToken', token);
		},
		resetUser: function () {
			store.commit('user/resetUser');
		},
		addUserInstitution: function (institution_id) {
			store.commit('user/addUserInstitution', institution_id);
		},
		sessionExpired() {
			Toast.open({
				message: 'Sessão expirada, repita o processo!',
				type: 'is-danger',
				position: 'is-bottom',
			});
			store.commit('user/resetStore');
			store.commit('cart/resetStore');
			store.commit('map/resetStore');
			store.commit('navbar/resetStore');
			store.commit('order/resetStore');
			store.commit('app/resetStore');
			store.commit('notifications/resetStore');
			router.push({ name: 'Login' });
		},
		setUserPoints(points) {
			store.commit('user/setUserPoints', points);
		},
	},
};
