// initial state
const state = {
	selectedPlace: {},
	selectedPlaceID: null,
};
const initial = JSON.parse(JSON.stringify(state));
// getters
const getters = {
	getSelectedPlace(state) {
		return state.selectedPlace;
	},
	getSelectedPlaceID(state) {
		return state.selectedPlaceID;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	setSelectedPlace(state, place) {
		if (place === '') {
			state.selectedPlace = {};
			state.selectedPlaceID = null;
		} else {
			state.selectedPlace = place;
			state.selectedPlaceID = place._id;
		}
	},
	resetProcess(state) {
		state.selectedPlace = {};
		state.selectedPlaceID = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
