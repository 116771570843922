// initial state
const state = {
	isBurgerOpen: false,
	isFilterOpen: false,
	isProfileOpen: false,
	isCartOpen: false,
	isNotificationsOpen: false,
	isSidebarOpen: false,
	isEditProductOpen: false,
	isAddProductOpen: false,
	isEditBagOpen: false,
	isAddBagOpen: false,
};

const initial = JSON.parse(JSON.stringify(state));

// getters
const getters = {
	getIsBurgerOpen(state) {
		return state.isBurgerOpen;
	},
	getIsFilterOpen(state) {
		return state.isFilterOpen;
	},
	getIsProfileOpen(state) {
		return state.isProfileOpen;
	},
	getIsCartOpen(state) {
		return state.isCartOpen;
	},
	getIsNotificationsOpen(state) {
		return state.isNotificationsOpen;
	},
	getIsSidebarOpen(state) {
		return state.isSidebarOpen;
	},
	getIsEditProductOpen(state) {
		return state.isEditProductOpen;
	},
	getIsAddProductOpen(state) {
		return state.isAddProductOpen;
	},
	getIsEditBagOpen(state) {
		return state.isEditBagOpen;
	},
	getIsAddBagOpen(state) {
		return state.isAddBagOpen;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	toggleBurgerOpen(state) {
		state.isBurgerOpen = !state.isBurgerOpen;
	},
	toggleFilterOpen(state) {
		state.isFilterOpen = !state.isFilterOpen;
	},
	toggleNotificationsOpen(state) {
		state.isNotificationsOpen = !state.isNotificationsOpen;
	},
	toggleCartOpen(state) {
		state.isCartOpen = !state.isCartOpen;
	},
	toggleProfileOpen(state) {
		state.isProfileOpen = !state.isProfileOpen;
	},
	toggleSidebarOpen(state) {
		state.isSidebarOpen = !state.isSidebarOpen;
	},
	toggleEditProductOpen(state) {
		state.isEditProductOpen = !state.isEditProductOpen;
	},
	toggleAddProductOpen(state) {
		state.isAddProductOpen = !state.isAddProductOpen;
	},
	setBurguerOpen(state, open) {
		state.isBurgerOpen = open;
	},
	setFilterOpen(state, open) {
		state.isFilterOpen = open;
	},
	setCartOpen(state, open) {
		state.isCartOpen = open;
	},
	setProfileOpen(state, open) {
		state.isProfileOpen = open;
	},
	setNotificationsOpen(state, open) {
		state.isNotificationsOpen = open;
	},
	setSidebarOpen(state, open) {
		state.isSidebarOpen = open;
	},
	setEditProductOpen(state, open) {
		state.isEditProductOpen = open;
	},
	setAddProductOpen(state, open) {
		state.isAddProductOpen = open;
	},
	setAddBagOpen(state, open) {
		state.isAddBagOpen = open;
	},
	setEditBagOpen(state, open) {
		state.isEditBagOpen = open;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
