import Vue from 'vue';

// initial state
const state = {
	lastLocation_delivery: {},
	lastLocation_picking: {},
	shared: {
		count: 0,
		pages: [],
	},
	recycled: {
		count: 0,
		pages: [],
	},
	myProducts: {
		count: 0,
		pages: [],
	},
	pending: {
		count: 0,
		pages: [],
	},
	favorites: [],
	refresh: {
		shared: 0,
		recycled: 0,
		myProducts: 0,
		pending: 0,
		favorites: 0,
	},
};
const initial = JSON.parse(JSON.stringify(state));

// getters
const getters = {
	getSharedProductsByPage: (state) => (_page) => {
		return state.shared.pages.find((page) => page.page == _page);
	},
	getSharedCount(state) {
		return state.shared.count;
	},

	getPendingProductsByPage: (state) => (_page) => {
		return state.pending.pages.find((page) => page.page == _page);
	},
	getPendingCount(state) {
		return state.pending.count;
	},

	getMyProductsByPage: (state) => (_page) => {
		return state.myProducts.pages.find((page) => page.page == _page);
	},
	getMyCount(state) {
		return state.myProducts.count;
	},

	getRecycledProductsByPage: (state) => (_page) => {
		return state.recycled.pages.find((page) => page.page == _page);
	},
	getRecycledCount(state) {
		return state.recycled.count;
	},

	getFavoriteProductsByPage: (state) => (_page) => {
		return state.favorites.pages.find((page) => page.page == _page);
	},
	getFavoriteCount(state) {
		return state.favorites.count;
	},

	getMyProducts(state) {
		return state.myProducts;
	},
	getPendingProducts(state) {
		return state.pending;
	},

	getFavoriteProducts(state) {
		return state.favorites;
	},

	getLastDeliveryLocation(state) {
		if (Object.keys(state.lastLocation_delivery).length === 0) return null;
		else return state.lastLocation_delivery;
	},
	getLastPickingLocation(state) {
		if (Object.keys(state.lastLocation_picking).length === 0) return null;
		else return state.lastLocation_picking;
	},

	getRefreshOf: (state) => (key) => {
		return state.refresh[key];
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	resetProductStoreByKey(s, key) {
		s[key] = JSON.parse(JSON.stringify(initial[key]));
	},

	execRefreshOn(state, key) {
		state.refresh[key]++;
	},

	saveLastPickingLocation(state, obj) {
		state.lastLocation_picking = obj;
	},
	saveLastDeliveryLocation(state, obj) {
		state.lastLocation_delivery = obj;
	},

	addSharedPage(state, __page) {
		let _page = __page;
		if (!Array.isArray(__page)) _page = [__page];

		for (let page of _page) {
			let index = (function () {
				for (let i in state.shared.pages) if (state.shared.pages[i].page == page.page) return i;
				return -1;
			})();
			if (index != -1) {
				Vue.set(state.shared.pages, index, page);
			} else {
				state.shared.pages.push(page);
			}
		}
	},
	setSharedCount(state, count) {
		state.shared.count = count;
	},

	addFavoritesPage(state, __page) {
		let _page = __page;
		if (!Array.isArray(__page)) _page = [__page];

		for (let page of _page) {
			let index = (function () {
				for (let i in state.favorites.pages) if (state.favorites.pages[i].page == page.page) return i;
				return -1;
			})();
			if (index != -1) {
				Vue.set(state.favorites.pages, index, page);
			} else {
				state.favorites.pages.push(page);
			}
		}
	},
	setFavoritesCount(state, count) {
		state.favorites.count = count;
	},

	addRecycledPage(state, __page) {
		let _page = __page;
		if (!Array.isArray(__page)) _page = [__page];

		for (let page of _page) {
			let index = (function () {
				for (let i in state.recycled.pages) if (state.recycled.pages[i].page == page.page) return i;
				return -1;
			})();
			if (index != -1) {
				Vue.set(state.recycled.pages, index, page);
			} else {
				state.recycled.pages.push(page);
			}
		}
	},
	setRecycledCount(state, count) {
		state.recycled.count = count;
	},

	addPendingPage(state, __page) {
		let _page = __page;
		if (!Array.isArray(__page)) _page = [__page];

		for (let page of _page) {
			let index = (function () {
				for (let i in state.pending.pages) if (state.pending.pages[i].page == page.page) return i;
				return -1;
			})();
			if (index != -1) {
				Vue.set(state.pending.pages, index, page);
			} else {
				state.pending.pages.push(page);
			}
		}
	},
	setPendingCount(state, count) {
		state.pending.count = count;
	},

	addMyProductsPage(state, __page) {
		let _page = __page;
		if (!Array.isArray(__page)) _page = [__page];

		for (let page of _page) {
			let index = (function () {
				for (let i in state.myProducts.pages) if (state.myProducts.pages[i].page == page.page) return i;
				return -1;
			})();
			if (index != -1) {
				Vue.set(state.myProducts.pages, index, page);
			} else {
				state.myProducts.pages.push(page);
			}
		}
	},
	setMyProductsCount(state, count) {
		state.myProducts.count = count;
	},

	// TO REMOVE
	setFavoriteProducts(state, obj) {
		state.favorites = obj;
	},

	// CHANGE
	// ADD PRODUCTS TO LAST PAGE
	addFavoriteProduct(state, obj) {
		state.favorites.push(obj);
	},
	//CHANGE
	// SEARCH PRODUCT ON PAGES AND REMOVE
	removeFavoriteProduct(state, _id) {
		for (let i in state.favorites) {
			if (state.favorites[i]._id == _id) Vue.delete(state.favorites, i);
		}
	},

	toggleFavoriteProduct(state, obj) {
		let found = false;
		for (let i in state.favorites) {
			if (state.favorites[i]._id == obj._id) {
				found = true;
				Vue.set(state.favorites, i, obj);
			}
		}

		for (let page in state.shared.pages) {
			for (let product in state.shared.pages[page].products) {
				if (state.shared.pages[page].products[product]._id == obj._id) {
					Vue.set(state.shared.pages[page].products[product], 'isFavorite', obj.isFavorite);
				}
			}
		}

		if (!found) {
			state.favorites.push(obj);
		}
	},

	// CHANGE
	// remove product from page
	removeMyProduct(state, _id) {
		for (let i in state.myProducts) {
			if (state.myProducts[i]._id == _id) state.myProducts.splice(i, 1);
		}
	},
	// CHANGE
	// update product from page
	updateMyProduct(state, obj) {
		for (let i in state.myProducts) {
			if (state.myProducts[i]._id == obj._id) Vue.set(state.myProducts, i, obj);
		}
	},

	updatePendingProduct(state, obj) {
		for (let i in state.pending) {
			if (state.pending[i]._id == obj._id) Vue.set(state.pending, i, obj);
		}
	},
	removePendingProduct(state, _id) {
		for (let i in state.pending) {
			if (state.pending[i]._id == _id) state.pending.splice(i, 1);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,

	mutations,
};
