// initial state
const state = {
	carts: [],
	isCartOpen: false,
	payment_type: '',
};
const initial = JSON.parse(JSON.stringify(state));
// getters
const getters = {
	getItemsCart(state) {
		return state.carts;
	},
	getPaymentType(state) {
		return state.payment_type;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	addToCart(state, obj) {
		let app_id = obj.app_id;
		let product = obj.product;
		let cart = -1;

		cart = state.carts.findIndex((c) => c.app_id == app_id);

		if (cart == -1) {
			state.carts.push({
				app_id,
				products: [product],
			});
		} else {
			if (state.carts[cart].products.filter((p) => p._id == product._id).length == 0) state.carts[cart].products.push(product);
		}
	},
	removeFromCart(state, obj) {
		let app_id = obj.app_id;
		let product_id = obj.product;
		let cart = -1;

		cart = state.carts.findIndex((c) => c.app_id == app_id);

		for (let p in state.carts[cart].products) {
			if (product_id == state.carts[cart].products[p]._id) state.carts[cart].products.splice(p, 1);
		}
	},
	changePaymentType(state, type) {
		state.payment_type = type;
	},
	resetCart(state, app_id) {
		for (let c in state.carts) {
			if (state.carts[c].app_id == app_id) {
				state.carts[c].products = [];
			}
		}
	},
	resetAllCarts(state) {
		for (let c in state.carts) {
			state.carts[c] = {
				app_id: state.carts[c].app_id,
				products: [],
			};
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,

	mutations,
};
