<template>
	<div class="navbar-modal has-text-centered has-navbar-fixed-top" :class="{ 'no-padding': getNotifications.length > 0 }">
		<div v-if="getNotifications.length <= 0">
			<ul class="navbar-modal-items">
				<h1 class="subtitle is-6">Não tem notificações!</h1>
			</ul>
		</div>
		<div v-else>
			<ul v-for="(notification, i) in getNotifications" v-bind:key="i" class="navbar-modal-items">
				<li
					class="clearfix"
					:class="{
						unread: !notification.read,
						read: notification.read,
					}"
				>
					<p class="not-text">{{ notification.text }}</p>
					<p class="not-text">
						{{ moment(notification.date).format('L') }}
						{{ moment(notification.date).format('LT') }}
					</p>
					<a @click="markRead(notification._id, notification.url)">Ver detalhes</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import { notifications } from '../../mixins/modules/notifications.js';

	export default {
		name: 'NotificationsModal',
		mixins: [notifications],
		props: {},
		data() {
			return {};
		},
		methods: {
			markRead(_id, url) {
				this.readNotification(_id);
				this.$socket.emit('notification_read', _id);
				// window.location.href = "." + url;
				this.$router.push(url);
				this.setNotificationsOpen(false);
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/variables/colors.scss';

	.no-padding {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 20px;
	}

	.navbar-modal-items {
		padding-top: 1px !important;
	}

	.subtitle {
		padding-top: 20px;
	}

	.unread {
		background-color: $primary;
		color: white;
		a {
			color: white;
		}
	}

	.read {
		background-color: lightgray;
		color: $primary-dark;
		a {
			color: $primary-dark;
		}
	}

	.clearfix {
		padding: 10px 20px;
		text-align: right;
		margin: 0 !important;
	}

	.not-text {
		text-align: left;
	}
</style>
